import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Layout from 'components/Order/Layout';
import MemberInfo from 'components/Member/MemberInfo';
import Auth from 'components/Auth';
import CcAuth from 'components/CcAuth';
import TitleName from 'components/UI/Header/TitleName';

import { useAPI } from 'utils/api';
import { useLoading } from 'utils/loading';
import { md } from 'utils/breakpoints';
import { API_RESPONSE } from 'apis/_const';

const Container = styled.div`
  @media (max-width: ${md}) {
    padding: 0px 0px 130px;
    background-color: #fff;
  }
`;

const TitleBar = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${md}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 108px;
    background-color: #ffffff;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    z-index: 1000;
    padding: 24px 15px;
  }
`;

const Title = styled.div`
  @media (max-width: ${md}) {
    display: none;
  }
`;

const BtnGroup = styled.div`
  display: flex;
  gap: 0 8px;

  @media (max-width: ${md}) {
    flex: 1;
  }
`;

const Btn = styled.div`
  width: 114px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;
  border-radius: 30px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  cursor: pointer;

  ${(props) =>
    props.primary &&
    css`
      background-color: #eafeff;
      border: 1px solid #5fd2da;
    `}
`;

const DeleteBtn = styled(Btn)`
  @media (max-width: ${md}) {
    display: none;
  }
`;

const EditBtn = styled(Btn)`
  @media (max-width: ${md}) {
    flex: 1;
    height: 60px;
    background-color: #5fd2da;
    border: 1px solid #5fd2da;
    border-radius: 40px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
  }
`;

const MemberEdit = (props) => {
  const pageTitle = '會員資料維護';
  const api = useAPI();
  const [param, setParam] = useState();
  const loading = useLoading();
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);

  const validateEmail = (email) => {
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const dataValid = (callback) => {
    if (validateEmail(param.email) === false) {
      callback(false, '請輸入正確的Email格式');
      return;
    }

    callback(true, '');
  };

  const updateMemberProfile = () => {
    console.log('updateMemberProfile', param);
    loading.show();
    dataValid((valid, msg) => {
      if (valid) {
        api
          .updateMemberProfile(param)
          .then((res) => {
            loading.dismiss();
            if (res.code !== API_RESPONSE.SUCCESS) {
              alert(res.reason);
              return;
            }
            alert('資料更新成功', () => {
              /* if (typeof window !== 'undefined') {
                window.location.reload();
              } */
            });
          })
          .catch(() => {
            loading.dismiss();
          });
      } else {
        loading.dismiss();
        alert(msg);
      }
    });
  };

  useEffect(() => {
    if (!ccAuthIsReady) return;
  }, [ccAuthIsReady]);

  return (
    <>
      <TitleName />
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      {ccAuthIsReady && (
        <Layout {...props} pageTitle={pageTitle}>
          <Auth />
          <Container>
            <TitleBar>
              <Title>{pageTitle}</Title>
              <BtnGroup>
                {/* <DeleteBtn>刪除帳號</DeleteBtn> */}
                <EditBtn onClick={() => updateMemberProfile()} primary>
                  修改
                </EditBtn>
              </BtnGroup>
            </TitleBar>
            <MemberInfo setParam={setParam} />
          </Container>
        </Layout>
      )}
    </>
  );
};

export default MemberEdit;
